// WaGen___HASHKEY__4bde3811_2019-12-09 14:16:24 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
 系统附件

*/
/* ---
标准CRUD方法：添加记录
*/
export function sysFj_add(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/add',
		method : 'post',
		data : {
			param : entity //SysFj
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：jgbm : Stringfjid : String
*/
export function sysFj_update(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/update',
		method : 'post',
		data : {
			param : entity //SysFj
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：jgbm : Stringfjid : String
*/
export function sysFj_remove(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/remove',
		method : 'post',
		data : {
			param : entity //SysFj
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function sysFj_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/selectiveDelete',
		method : 'post',
		data : {
			param : entity //SysFj
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function sysFj_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/selectiveUpdate',
		method : 'post',
		data : {
			param : entity //SysFj
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function sysFj_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/selectiveAdd',
		method : 'post',
		data : {
			param : entity //SysFj
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：jgbm : Stringfjid : String
*/
export function sysFj_fetch(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/fetch',
		method : 'post',
		data : {
			param : entity //SysFj
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function sysFj_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/fetchAll',
		method : 'post',
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function sysFj_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/selectiveGet',
		method : 'post',
		data : {
			param : entity, //SysFj
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function sysFj_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/selectiveGetOne',
		method : 'post',
		data : {
			param : entity //SysFj
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function sysFj_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/selectiveCount',
		method : 'post',
		data : {
			param : entity //SysFj
		}
	})
}

/* ---
多记录插入
*/
export function sysFj_insertList(list, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/insertList',
		method : 'post',
		data : {
			param : list //List<SysFj>
		}
	})
}

/* ---
多记录更新,需要的主键字段：jgbm : Stringfjid : String
*/
export function sysFj_updateList(list, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/updateList',
		method : 'post',
		data : {
			param : list //List<SysFj>
		}
	})
}

/* ---
多记录删除,需要的主键字段：jgbm : Stringfjid : String
*/
export function sysFj_deleteList(list, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/SysFj/deleteList',
		method : 'post',
		data : {
			param : list //List<SysFj>
		}
	})
}

