// WaGen___HASHKEY__428a4bfb_2021-06-11 10:26:04 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
市场化售电电量申报计划开放
*/
export function scsdService_dlsbjhKf(scsdDlsbjh, meta) {
	return fetch({
		url : 'psdmsschjy/service/ScsdService/dlsbjhKf',
		method : 'post',
		headers : {
		},
		data : {
			param : scsdDlsbjh //ScsdDlsbjh
		}
	})
}

/* ---
市场化售电电量申报计划作废
*/
export function scsdService_dlsbjhZf(jhbh, meta) {
	return fetch({
		url : 'psdmsschjy/service/ScsdService/dlsbjhZf',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jhbh : jhbh //String
			}
		}
	})
}

/* ---
查询市场化售电电量申报计划及明细
*/
export function scsdService_getDlsbjhAndJhmx(yhbh, meta) {
	return fetch({
		url : 'psdmsschjy/service/ScsdService/getDlsbjhAndJhmx',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				yhbh : yhbh //String
			}
		}
	})
}

/* ---
查询市场化售电申报电量记录
*/
export function scsdService_getSbdljl(yhbh, meta) {
    return fetch({
        url : 'psdmsschjy/service/ScsdService/getSbdljl',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                yhbh : yhbh //String
            }
        }
    })
}