import { sysFj_selectiveGet, sysFj_add, sysFj_remove } from '@/api/psdmszhwh/zhwhschema/SysFjAPI';
import { sysFiles_selectiveDelete } from '@/api/psdmsfiles/fileschema/SysFilesAPI';

import axios from 'axios';
import store from '@/store';
import { parseTime } from '@/utils';

const MAX_SIZE =  1024 * 1024 * 200; // 200M
const PREVIEW_BASE_URL = '/psdmsfiles/filePreview/preview';

const ACCEPT_FILE_TYPES = [
  'image/bmp','image/gif','image/jpeg','image/png','image/tiff',
  'text/plain','application/pdf',
  'application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/zip','application/x-rar-compressed','application/x-zip-compressed','application/x-7z-compressed',
  'video/x-msvideo','video/webm','video/mpeg','video/ogg',
  'audio/aac','audio/mpeg','audio/wav','audio/ogg'
];

export function fileManage_delete(file) {
  let fileId = file.fjid ? file.fjid : file.fileId;
  return new Promise((resolve, reject) => {
    if (fileId) {
      sysFj_remove({
        fjid: fileId,
        jgbm: file.jgbm
      }).then(res=>{
        sysFiles_selectiveDelete({
          fileId : fileId
        }).then(resp => {
          resolve(resp);
        }).catch(err => {
          reject(err);
        });
      }).catch(err => {
        reject(err);
      });
    } else {
      reject(new Error("删除失败： fileId 不存在!"));
    }
  });
}

export function fileManage_upload(file, options) {
  let uploadUrl = '/psdmsfiles/fileManage/upload';
  let headers = {
    'Content-Type': 'multipart/form-data',
    'loginToken': options.loginToken
  };

  let formData = new FormData();
  const fileRaw = file.file ? file.file : file.raw;
  formData.append('file',fileRaw);
  formData.append('day', -1);
  const isAcceptType = ACCEPT_FILE_TYPES.includes(fileRaw.type);
  const isLimitSize = fileRaw.size <  MAX_SIZE;
  const czyh = store.state.user.czyh;

  if (isAcceptType && isLimitSize) {
    axios.post(uploadUrl, formData, { 
      headers,
      onUploadProgress:function(progressEvent){
        if(progressEvent.lengthComputable){
          let percentage = (progressEvent.loaded / progressEvent.total * 100 | 0);
          file.status = 'uploading';
          file.percentage = percentage;
          options.onProgress(progressEvent, percentage, file);
        }
      }
    }).then(response => {
      console.log(response);
      const respData = response.data;
      const respFile = respData.content;
      if (respData.code && respData.code === 2000) {
        file.percentage = 100;
        file.status = 'done';
        // 添加到系统附件
        // Data Async
        let datetime = parseTime(new Date(), '{y}-{m}-{d} {h}:{m}:{s}');
        let params = options.params || {};
        Object.assign(params, {
          fjid: respData.content.fileId,
          fjmc: respData.content.fileName,
          cjry: czyh, // 创建人员
          cjsj: datetime, // 创建时间
          czry: czyh, // 操作人员
          czsj: datetime, // 操作时间
          fjdx: respData.content.size, // 附件大小
          wjlx: respData.content.type, // 文件类型
          wjdz:  PREVIEW_BASE_URL+'?fileId=' + respData.content.fileId, // 文件地址
          contentType: respData.content.contentType
        })
        file.fileName = respData.content.fileName;
        sysFj_add(params).then(res => { //渲染节点信息
          Object.assign(file, res.content);
          options.onSuccess(file);
        }).catch(err => {
          file.status = "failed";
          options.onFail(err, file);
        });
      } else {
        file.status = "failed";
        options.onFail(new Error(response.content), file);
      }
    }).catch(err => {
      file.status = "failed";
      options.onFail(err, file);
    });
  } else {
    const reason = !isAcceptType ? '不支持的文件格式' : '文件大小超过限制';
    options.onFail(new Error(reason), file);
  }
}

export function fileManage_download(file, loginToken) {
  let fileId = file.fjid ? file.fjid : file.fileId;
  if ($('#downloadform').length === 0) {
    var $form = $('<form method="post" target="name" id="downloadform"></form>');
    $form.attr('action', 'psdmsfiles/fileManage/download');
    $form.append('<input type="hidden"  id="fileID" name="fileID" value="' + fileId + '" />');
    $form.append('<input type="hidden"  id="loginToken" name="loginToken" value="' + loginToken + '" />');
    $form.appendTo($('body'));

    var $ifame = $('<iframe name="name" style="display: none"></iframe>');
    $ifame.appendTo($('body'))
  }

  $('#fileID').val(fileId);
  $('#downloadform').submit();
}

export function fileManage_getList(params) {
  return new Promise((resolve, reject) => {
    sysFj_selectiveGet(params).then(res => {
      let list = res.content.map(item => {
        Object.assign(item, {
          fileId: item.fjid,
          fileName: item.fjmc,
          size: item.fjdx || 0,
          type: item.wjlx,
          url: PREVIEW_BASE_URL+'?fileId=' + item.fjid,
          status: 'done',
          file: {
            name: item.fjmc,
            type: item.contentType
          }
        });
        return item;
      });
      resolve(list);
    }).catch(err => {
      reject(err);
    });
  });
}

export function fileManage_preview(fileID) {

}