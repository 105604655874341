<template>
  <page-view :title="pageTitle" :nav-border="false" left-arrow>
    <template #nav-right>
      <yhbh-select v-model="form.yhbh" @confirm="yhbhChangeHandle" :custom-style="{}"/>
    </template>
    <loading-layout ref="loadingLayoutOuter"  error-text="绑定户号信息查询失败" empty-text="未绑定用户编号">
        <template #empty>
          <yhbh-empty fromPage="yhzd-query"/>
        </template>
      <loading-layout ref="loadingLayout" :error-text="pageErrortext">
        <template #empty>
          <van-empty :description="pageErrortext" :image="require('@/assets/images/load-error.png')">
            <van-button type="primary" class="bottom-button" @click="pageBack" size="small">返回</van-button>
          </van-empty>
        </template>
        <van-form :show-error="true" :show-error-message="false" :scroll-to-error="true" :validate-first="true"
          @submit="onSubmit" @failed="onFailed">
          <van-cell-group class="mt-10">
            <van-field v-model="form.yhbh" name="yhbh" label="用户编号"/>
            <van-field v-model="form.yhmc" name="yhmc" label="用户名称" />
            <van-cell title="用户标志" value="市场化交易用户"/>
            <van-field v-model="form.jhyf" name="jhyf" label="申报月份" readonly/>
            <vant-select 
              v-model="form.jbflb"
              :readonly="sbjhxx.jbfsfkfbz == '0' ? true : false"
              :disable="sbjhxx.jbfsfkfbz == '0' ? true : false"
              name="gzlx"
              label="基本费计算方式"
              placeholder="请选择基本费计算方式"
              :columns="[
                { label: '按容量计', value: '1' },
                { label: '按需量计', value: '2' },
              ]"
            />
            <van-field required v-model="form.jhdl" name="jhdl" label="计划电量" type="number" class="jhld-filed"
              :rules="[{ required: true, message: '请输入计划电量' }]">
            </van-field>
            <p class="jhld-filed--tip">{{jhdl_DX}}</p>
            
            <van-cell title="附件" class="van-cell--fj">
              <template #label>
                <p class="text-muted" style="margin-bottom: 8px;">请上传纸质申报盖章附件</p>
                <van-uploader 
                  v-model="fjList" 
                  :after-read="afterRead" 
                  :before-delete="beforeDelete"
                  :max-count="6" 
                  :preview-image="true" 
                  class="schjy-fj-uploader"
                  @delete="deleteFj">
                </van-uploader>
              </template>
            </van-cell>
          </van-cell-group>

          <van-cell-group class="mt-10">
            <van-cell>
                <span>申报期限    </span>
                <van-tag text-color="gray" color="white">{{form.sbqx}}</van-tag>
            </van-cell>
          </van-cell-group>

          <div style="margin: 16px;" class="text-center">
            <van-button type="primary" native-type="submit" class="mr-10" size="small">申报提交</van-button>
            <van-button type="primary" native-type="button" plain size="small" @click="pageBack">返回</van-button>
          </div>
        </van-form>
      </loading-layout>
    </loading-layout>
  </page-view>
</template>

<script>
  import { Uploader } from 'vant';
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import { digitToChinese, parseTime } from '@/utils';
  import VantSelect from '@/components/VantSelect/index.vue'
  
  import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
  import YhbhSelect from '@/components/YhbhSelect/index.vue';

  import { scsdService_getDlsbjhAndJhmx } from '@/api/psdmsschjy/service/ScsdServiceAPI';
  import { scsdDlsbjhmx_update } from '@/api/psdmsschjy/scsdschema/ScsdDlsbjhmxAPI';
  import { fileManage_upload, fileManage_getList, fileManage_delete} from '@/utils/fileManage';

  export default {
    name: 'jydlsb',
    components: {
      [Uploader.name]: Uploader,
      VantSelect,
      
      YhbhEmpty,
      YhbhSelect,
    },
    data() {
      return {
        pageTitle: "交易电量申报",
        pageErrortext: '',
        yhbh: null,
        yhJbxx: {},
        sbjhxx:{},
        sbjhmx: {},
        form: {
          yhbh: null,
          yhmc: '',
          yhbz: '9',
          jhyf: null,
          jbflb: '1',
          jhdl: 0,
          sbqx: null
        },
        fjList: []
      }
    },
    computed: {
      ...mapGetters(['token','jgbm','dybm','czyh','dlzh','yhid','yhsj']),
      jhdl_DX() {
        return digitToChinese(this.form.jhdl);
      }
    },
    methods: {
      pageBack() {
        this.$router.go(-1)
      },
      yhbhChangeHandle(yhxx) {
        this.yhbh = yhxx.yhbh;
        this.pageSetup();
      },
      pageSetup() {
        this.$refs['loadingLayout'].setStatus('loading');
        // this.yhbh = '3002948201';
        scsdService_getDlsbjhAndJhmx(this.yhbh).then(res => {
          if (res.content.status == 200) {
            this.sbjhxx = res.content.data.scsdDlsbjh;
            this.sbjhmx = res.content.data.scsdDlsbjhmx;

            this.form = {
              yhbh: this.sbjhmx.yhbh,
              yhmc: this.sbjhmx.yhmc,
              jhyf: this.sbjhxx.jhyf,
              jbflb: this.sbjhmx.jbflb,
              jhdl: this.sbjhmx.jhdl || 0,
              sbqx: this.sbjhxx.kssj + ' 至 ' + this.sbjhxx.jzsj
            }
            this.getFjList();
            this.$refs['loadingLayout'].setStatus('success');
          } else {
            this.pageErrortext = res.content.message;
            this.$refs['loadingLayout'].setStatus('empty');
          }
        }).catch(err => {
          this.pageErrortext = '查询市场化售电电量申报计划失败';
          this.$refs['loadingLayout'].setStatus('net-error');
        });
      },
      onSubmit(values) {
        if (this.form.jhdl && this.form.jhdl > 0) {
          this.sbjhmx.jhdl = this.form.jhdl;
          this.sbjhmx.jbflb = this.form.jbflb;
          this.sbjhmx.sbrid = this.yhid;
          this.sbjhmx.sbrsj = this.yhsj;
          this.sbjhmx.sbsj = parseTime(new Date(), '{y}-{m}-{d} {h}:{m}:{s}');
          this.sbjhmx.dqzt = '-1';

          this.$toast.loading('正在提交')
          scsdDlsbjhmx_update(this.sbjhmx).then(res => {
            this.$toast.success('申报成功!')
          }).catch(err => {
            this.$toast('申报失败!')
          })
        } else {
          this.$toast('请输入正确的计划电量')
        }
      },
      onFailed({
        values,
        errors
      }) {
        console.log('failed:, Errors:', errors)
        this.$notify({
          type: 'danger',
          message: errors[0].message
        });
      },
      afterRead(file, {index}) {
        console.log(file, index);
        const {jgbm, yhbh,yhmc, jhyf} = this.sbjhmx;
        const datetimeNow = parseTime(new Date(), '{y}-{m}-{d} {h}:{m}:{s}');
        let vm = this;
        fileManage_upload(file, {
          params: {
            fjfl: '1', // 用户资料
            bh: yhbh + '-' +  jhyf,
            gnid: 'wx-jydlsb',
            jgbm: jgbm,
            bzsm: `用户 ${yhmc}[${yhbh}] 交易电量申报附件（上传于${datetimeNow}）。`,
          },
          loginToken: this.token,
          onProgress: function (ev, percentage, file) {
            console.log("onProgress:", ev, percentage, file)
            vm.fjList.splice(index, 1, file);
          },
          onSuccess: function(file) {
            console.log("onSuccess:", file);
            vm.fjList.splice(index, 1, file);
          },
          onFail: function(err, file) {
            console.log("FAIL:", err, file)
            vm.$toast('上传失败：'+ err.message);
            vm.fjList.splice(index, 1, file);
          }
        });
      },
      getFjList() {
        const {jgbm, yhbh, jhyf} = this.sbjhmx;
        const params = {
          gnid: "wx-jydlsb",
          jgbm: jgbm,
          bh: yhbh + '-' +  jhyf,
        }

        fileManage_getList(params).then(data => {
          console.log("LIST:", data);
          this.fjList = data;
        });
      },
      beforeDelete() {
        return this.$dialog.confirm({
          title: "提示",
          message: "是否删除所选附件?"
        });
      },
      deleteFj(file, index) {
        fileManage_delete(file).then(res => {
          this.$toast('已删除附件');
        }).catch(err => {
          this.$toast('附件删除失败:'+ err.message);
        });
      }
    },
    mounted() {
      this.$refs['loadingLayoutOuter'].setStatus('loading');
      this.$store.dispatch('getYhbhList').then(list => {
        if (list.length == 0) {
          this.$refs['loadingLayoutOuter'].setStatus('empty');
        } else {
          this.$refs['loadingLayoutOuter'].setStatus('success');
          this.yhbh = list[0].yhbh;
          this.pageSetup();
        }
      }).catch(err => {
        this.$refs['loadingLayoutOuter'].setStatus('net-error');
      });
    },
  }
</script>

<style lang="scss" scoped>
  // .van-button--small {
  //   width: 100px;
  // }

  .jhld-filed /deep/ {
    .van-field__value {
      font-size: 15px;
      font-weight: 700;
    }
  }

  .jhld-filed--tip {
    font-size: 12px;
    color: #999;
    text-align: right;
    margin: 5px 16px
  }

  .schjy-fj-uploader /deep/{
    // width: 100%;
    // .van-uploader__wrapper {
    //   display: block;
    //   flex: 1;
    // }
  }
  .van-cell--fj /deep/{
    flex-direction: column;
  }
  .fj-list {
    margin: 10px 0;
    text-align: left;
  }
  .fj-item /deep/{
    padding: 10px 0;
    display: flex;
    border-bottom: 1px solid #eee;
    align-items: center;
    justify-content: space-between;
    .fj-content {
      flex: 1;
      margin-right: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    
    .filename {
      color: #646566;
      font-size: 14px;
    }
    .filesize {
      color: #969799;
      font-size: 12px;
    }

    .fj-actions .van-button {
      padding: 0;
      border: 0;
    }
  }
</style>